import Vue from "vue";
import Vuex from "vuex";
import { createDirectStore } from "direct-vuex";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IProduct, IProductCategory } from "@common/product";
import { IIngredient, IIngredientCategory } from "@common/ingredient";
import { IUser } from "@common/user";
import { IAllergen } from "@common/allergen";
import { IStation, IStationOperator, ITablet, IStationOperatorsGetAllReq } from "@common/station";
import { INotificationMonitoring } from "@common/notificationMonitoring";
import { IExtraActivityMonitoring } from "@common/extraActivityMonitoring";
import { IProductionStandardTime } from "@common/productionStandardTime";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";

Vue.use(Vuex);

const {
	store,
	rootActionContext,
	moduleActionContext,
	rootGetterContext,
	moduleGetterContext
} = createDirectStore({
	state: {
		appTitle: "" as string,
		products: [] as IProduct[],
		productsInfos: {
			shelfLifeOptions: ["6h", "12h"]
		},
		ingredients: [] as IIngredient[],
		ingredientsInfos: {
			units: ["kg", "buc", "l"],
			storageConditions: ["Congelat", "Refrigerat", "Ambiental"],
			printable: [
				{
					text: "Yes",
					value: true
				},
				{
					text: "No",
					value: false
				}
			],
			valabilityAfterDefrostOptions: [
				{
					text: "None",
					value: null
				},
				{
					text: "3h",
					value: "3h"
				},
				{
					text: "6h",
					value: "6h"
				},
				{
					text: "12h",
					value: "12h"
				},
				{
					text: "24h",
					value: "24h"
				},
				{
					text: "48h",
					value: "48h"
				},
				{
					text: "72h",
					value: "72h"
				}
			],
			valabilityAfterBakingOptions: [
				{
					text: "None",
					value: null
				},
				{
					text: "3h",
					value: "3h"
				},
				{
					text: "6h",
					value: "6h"
				},
				{
					text: "12h",
					value: "12h"
				},
				{
					text: "24h",
					value: "24h"
				},
				{
					text: "48h",
					value: "48h"
				},
				{
					text: "72h",
					value: "72h"
				}
			],
			valabilityAfterSlicingOptions: [
				{
					text: "None",
					value: null
				},
				{
					text: "3h",
					value: "3h"
				},
				{
					text: "6h",
					value: "6h"
				},
				{
					text: "12h",
					value: "12h"
				},
				{
					text: "24h",
					value: "24h"
				},
				{
					text: "48h",
					value: "48h"
				},
				{
					text: "72h",
					value: "72h"
				}
			],
			preparationTypeOptions: [
				{
					text: "None",
					value: null
				},
				{
					text: "Coacere",
					value: "bake"
				},
				{
					text: "Decongelare",
					value: "defrost"
				},
				{
					text: "Prajire",
					value: "frying"
				},
				{
					text: "Feliere",
					value: "slicing"
				}
			]
		},
		allergensInfos: {
			states: ["Yes", "No", "Maybe"]
		},
		usersInfos: {
			roles: ["gas-admin", "admin", "sys-admin"]
		},
		dashboard: {
			filters: {
				grouping: ["Daily", "Hourly", "None"],
				soldType: ["TS/TG", "TS+TG", "TS", "TG"],
				elementType: ["Reception batch", "Product", "Ingredient"],
				dateFilterBy: {
					sales: ["Sold Date", "Production Date"],
					scrapping: ["Scrapping Date", "Production Date"]
				},
				causes: {
					products: ["Any", "Automatic scrapping", "Produs deteriorat ce nu mai poate fi vandut", "Protocol/degustare angajati", "Analize laborator", "Automatic product expiration", "Corectie eticheta eronata", "Scrap only to server"],
					ingredients: ["Any", "Automatic scrapping", "Preparare", "Produs deteriorat ce nu mai poate fi vandut", "Protocol/degustare angajati", "Analize laborator", "Automatic leftovers", "Corectie eticheta eronata", "Scrap only to server"]
				}
			},
			rangePresets: ["Today", "Yesterday", "Last 7 Days", "Last 30 Days", "This Month", "Last Month", "Custom Range"],
			timePresets: {
				start: [
					"00:00",
					"01:00",
					"02:00",
					"03:00",
					"04:00",
					"05:00",
					"06:00",
					"07:00",
					"08:00",
					"09:00",
					"10:00",
					"11:00",
					"12:00",
					"13:00",
					"14:00",
					"15:00",
					"16:00",
					"17:00",
					"18:00",
					"19:00",
					"20:00",
					"21:00",
					"22:00",
					"23:00"
				],
				end: [
					"00:59",
					"01:59",
					"02:59",
					"03:59",
					"04:59",
					"05:59",
					"06:59",
					"07:59",
					"08:59",
					"09:59",
					"10:59",
					"11:59",
					"12:59",
					"13:59",
					"14:59",
					"15:59",
					"16:59",
					"17:59",
					"18:59",
					"19:59",
					"20:59",
					"21:59",
					"22:59",
					"23:59"
				]
			},
			colorPresets: {
				407639: "#34A853",
				411128: "#EA4335",
				415429: "#292F33",
				422924: "#7B0099",
				427432: "#4285F4",
				427486: "#FBBC05",
				427491: "#66757F",
				427516: "#A4C639",
				427518: "#FF9900",
				428417: "#bdcebe",
				428419: "#8D6E63",
				428441: "#18FFFF",
				429649: "#FFD700",
				429681: "#FF69B4",
				429687: "#BF6347",
				429924: "#A04500",
				806104: "#034f84",
				806141: "#ffef96"
			} as {
				[token: string]: string;
			}
		},
		productCategories: [] as IProductCategory[],
		ingredientCategories: [] as IIngredientCategory[],
		allergens: [] as IAllergen[],
		tablets: [] as ITablet[],
		users: [] as IUser[],
		operators: [] as IStationOperator[],
		stations: [] as IStation[],
		notificationMonitorings: [] as INotificationMonitoring[],
		extraActivityMonitorings: [] as IExtraActivityMonitoring[],
		productionStandardTimes: [] as IProductionStandardTime[]
	},
	mutations: {
		changeAppTitle (state, payload) {
			state.appTitle = payload.newAppTitle;
		},
		updateProducts (state, payload) {
			state.products = payload.products;
		},
		updateIngredients (state, payload) {
			state.ingredients = payload.ingredients;
		},
		updateProductCategories (state, payload) {
			state.productCategories = payload.categories;
		},
		updateIngredientCategories (state, payload) {
			state.ingredientCategories = payload.categories;
		},
		updateAllergens (state, payload) {
			state.allergens = payload.allergens;
		},
		updateTablets (state, payload) {
			state.tablets = payload.tablets;
		},
		updateUsers (state, payload) {
			state.users = payload.users;
		},
		updateOperators (state, payload) {
			state.operators = payload.operators;
		},
		updateStations (state, payload) {
			state.stations = payload.stations;
		},
		updateNotificationMonitorings (state, payload) {
			state.notificationMonitorings = payload.notificationMonitorings;
		},
		updateExtraActivityMonitorings (state, payload) {
			state.extraActivityMonitorings = payload.extraActivityMonitorings;
		},
		updateProductionStandardTimes (state, payload) {
			state.productionStandardTimes = payload.productionStandardTimes;
		},
		addProduct (state, payload) {
			state.products.push(payload.newProduct);
		},
		addIngredient (state, payload) {
			state.ingredients.push(payload.newIngredient);
		},
		addAllergen (state, payload) {
			state.allergens.push(payload.newAllergen);
		},
		addUser (state, payload) {
			state.users.push(payload.newUser);
		},
		addOperator (state, payload) {
			state.operators.push(payload.newOperator);
		},
		addStation (state, payload) {
			state.stations.push(payload.newStation);
		},
		addNotificationMonitoring (state, payload) {
			state.notificationMonitorings.push(payload.newNotificationMonitoring);
		},
		addExtraActivityMonitoring (state, payload) {
			state.extraActivityMonitorings.push(payload.newExtraActivityMonitoring);
		},
		addProductionStandardTime (state, payload) {
			state.productionStandardTimes.push(payload.newProductionStandardTime);
		},
		editProduct (state, payload) {
			const oldProductPos = state.products.indexOf(payload.product.old);
			state.products.splice(oldProductPos, 1, payload.product.new);
		},
		editIngredient (state, payload) {
			const oldIngredientPos = state.ingredients.indexOf(payload.ingredient.old);
			state.ingredients.splice(oldIngredientPos, 1, payload.ingredient.new);
		},
		editAllergen (state, payload) {
			const oldAllergenPos = state.allergens.indexOf(payload.allergen.old);
			state.allergens.splice(oldAllergenPos, 1, payload.allergen.new);
		},
		editUser (state, payload) {
			const oldUserPos = state.users.indexOf(payload.user.old);
			state.users.splice(oldUserPos, 1, payload.user.new);
		},
		editOperator (state, payload) {
			const oldOperatorPos = state.operators.indexOf(payload.operator.old);
			state.operators.splice(oldOperatorPos, 1, payload.operator.new);
		},
		editStation (state, payload) {
			const oldStationPos = state.stations.indexOf(payload.station.old);
			state.stations.splice(oldStationPos, 1, payload.station.new);
		},
		editNotificationMonitoring (state, payload) {
			const oldNotificationMonitoringPos = state.notificationMonitorings.indexOf(payload.notificationMonitoring.old);
			state.notificationMonitorings.splice(oldNotificationMonitoringPos, 1, payload.notificationMonitoring.new);
		},
		editExtraActivityMonitoring (state, payload) {
			const oldExtraActivityMonitoringPos = state.extraActivityMonitorings.indexOf(payload.extraActivityMonitoring.old);
			state.extraActivityMonitorings.splice(oldExtraActivityMonitoringPos, 1, payload.extraActivityMonitoring.new);
		},
		editProductionStandardTime (state, payload) {
			state.productionStandardTimes.map((productionStandardTime) => {
				if (productionStandardTime.productId === payload.productionStandardTime.old.productId) {
					state.productionStandardTimes.splice(state.productionStandardTimes.indexOf(productionStandardTime), 1, payload.productionStandardTime.new);
				}
			});
		},
		deleteProduct (state, payload) {
			const productPos = state.products.indexOf(payload.product);
			state.products.splice(productPos, 1);
		},
		deleteIngredient (state, payload) {
			const ingredientPos = state.ingredients.indexOf(payload.ingredient);
			state.ingredients.splice(ingredientPos, 1);
		},
		deleteAllergen (state, payload) {
			const allergenPos = state.allergens.indexOf(payload.allergen);
			state.allergens.splice(allergenPos, 1);
		},
		deleteTablet (state, payload) {
			const tabletPos = state.tablets.indexOf(payload.tablet);
			state.tablets.splice(tabletPos, 1);
		},
		deleteUser (state, payload) {
			const userPos = state.users.indexOf(payload.user);
			state.users.splice(userPos, 1);
		},
		deleteOperator (state, payload) {
			const operatorPos = state.operators.indexOf(payload.operator);
			state.operators.splice(operatorPos, 1);
		},
		deleteStation (state, payload) {
			const stationPos = state.stations.indexOf(payload.station);
			state.stations.splice(stationPos, 1);
		},
		deleteNotificationMonitoring (state, payload) {
			const notificationMonitoringPos = state.notificationMonitorings.indexOf(payload.notificationMonitoring);
			state.notificationMonitorings.splice(notificationMonitoringPos, 1);
		},
		deleteExtraActivityMonitoring (state, payload) {
			const extraActivityMonitoringPos = state.extraActivityMonitorings.indexOf(payload.extraActivityMonitoring);
			state.extraActivityMonitorings.splice(extraActivityMonitoringPos, 1);
		},
		deleteProductionStandardTime (state, payload) {
			const productionStandardTimePos = state.productionStandardTimes.indexOf(payload.productionStandardTime);
			state.productionStandardTimes.splice(productionStandardTimePos, 1);
		}
	},
	actions: {
		changeAppTitle (context, newAppTitle: string) {
			context.commit({
				type: "changeAppTitle",
				newAppTitle
			});
		},
		async fetchProducts (context) {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${context.getters.serverURL}/product/all`,
			};
			const res: AxiosResponse<IServerRES<IProduct[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				context.commit({
					type: "updateProducts",
					products: res.data.payload
				});
			}
		},
		async fetchIngredients (context) {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${context.getters.serverURL}/ingredient/all`,
			};
			const res: AxiosResponse<IServerRES<IIngredient[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				context.commit({
					type: "updateIngredients",
					ingredients: res.data.payload
				});
			}
		},
		async fetchOnlyUsedIngredients (context) {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${context.getters.serverURL}/ingredient/onlyUsed`,
			};
			const res: AxiosResponse<IServerRES<IIngredient[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				context.commit({
					type: "updateIngredients",
					ingredients: res.data.payload
				});
			}
		},
		async fetchProductCategories (context) {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${context.getters.serverURL}/product/categories`,
			};
			const res: AxiosResponse<IServerRES<IProductCategory[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				context.commit({
					type: "updateProductCategories",
					categories: res.data.payload
				});
			}
		},
		async fetchIngredientCategories (context) {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${context.getters.serverURL}/ingredient/categories`,
			};
			const res: AxiosResponse<IServerRES<IIngredientCategory[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				context.commit({
					type: "updateIngredientCategories",
					categories: res.data.payload
				});
			}
		},
		async fetchAllergens (context) {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${context.getters.serverURL}/allergen/all`,
			};
			const res: AxiosResponse<IServerRES<IAllergen[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				context.commit({
					type: "updateAllergens",
					allergens: res.data.payload
				});
			}
		},
		async fetchTablets (context) {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${context.getters.serverURL}/tablet/all`,
			};
			const res: AxiosResponse<IServerRES<ITablet[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				context.commit({
					type: "updateTablets",
					tablets: res.data.payload
				});
			}
		},
		async fetchUsers (context) {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${context.getters.serverURL}/user/all`,
			};
			const res: AxiosResponse<IServerRES<IUser[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				context.commit({
					type: "updateUsers",
					users: res.data.payload
				});
			}
		},
		async fetchOperators (context, gasStations: IStation[]) {
			const data: IStationOperatorsGetAllReq = {
				gasStations
			};
			const options: AxiosRequestConfig = {
				method: "POST",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				data,
				url: `${context.getters.serverURL}/station/operator/all`,
			};
			const res: AxiosResponse<IServerRES<IStationOperator[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				context.commit({
					type: "updateOperators",
					operators: res.data.payload
				});
			}
		},
		async fetchStations (context) {
			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${context.getters.serverURL}/station/all`,
				};

				const res: AxiosResponse<IServerRES<IStation[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					context.commit({
						type: "updateStations",
						stations: res.data.payload
					});
				}
			} catch (err) {
				console.error(err);
			}
		},
		async fetchProductionStandardTimes (context) {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${context.getters.serverURL}/production-standard-time/getAll`,
			};
			const res: AxiosResponse<IServerRES<IProductionStandardTime[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				context.commit({
					type: "updateProductionStandardTimes",
					productionStandardTimes: res.data.payload
				});
			}
		},
		addProduct (context, newProduct: IProduct) {
			context.commit({
				type: "addProduct",
				newProduct
			});
		},
		addIngredient (context, newIngredient: IIngredient) {
			context.commit({
				type: "addIngredient",
				newIngredient
			});
		},
		addAllergen (context, newAllergen: IAllergen) {
			context.commit({
				type: "addAllergen",
				newAllergen
			});
		},
		addUser (context, newUser: IUser) {
			context.commit({
				type: "addUser",
				newUser
			});
		},
		addOperator (context, newOperator: IStationOperator) {
			context.commit({
				type: "addOperator",
				newOperator
			});
		},
		addStation (context, newStation: IStation) {
			context.commit({
				type: "addStation",
				newStation
			});
		},
		addNotificationMonitoring (context, newNotificationMonitoring: INotificationMonitoring) {
			context.commit({
				type: "addNotificationMonitoring",
				newNotificationMonitoring
			});
		},
		addExtraActivityMonitoring (context, newExtraActivityMonitoring: IExtraActivityMonitoring) {
			context.commit({
				type: "addExtraActivityMonitoring",
				newExtraActivityMonitoring
			});
		},
		addProductionStandardTime (context, newProductionStandardTime: IProductionStandardTime) {
			context.commit({
				type: "addProductionStandardTime",
				newProductionStandardTime
			});
		},
		editProduct (context, product: {old: IProduct; new: IProduct}) {
			context.commit({
				type: "editProduct",
				product
			});
		},
		editIngredient (context, ingredient: {old: IIngredient; new: IIngredient}) {
			context.commit({
				type: "editIngredient",
				ingredient
			});
		},
		editAllergen (context, allergen: {old: IAllergen; new: IAllergen}) {
			context.commit({
				type: "editAllergen",
				allergen
			});
		},
		editUser (context, user: {old: IUser; new: IUser}) {
			context.commit({
				type: "editUser",
				user
			});
		},
		editOperator (context, operator: {old: IStationOperator; new: IStationOperator}) {
			context.commit({
				type: "editOperator",
				operator
			});
		},
		editStation (context, station: {old: IStation; new: IStation}) {
			context.commit({
				type: "editStation",
				station
			});
		},
		editNotificationMonitoring (context, notificationMonitoring: {old: INotificationMonitoring; new: INotificationMonitoring}) {
			context.commit({
				type: "editNotificationMonitoring",
				notificationMonitoring
			});
		},
		editExtraActivityMonitoring (context, extraActivityMonitoring: {old: IExtraActivityMonitoring; new: IExtraActivityMonitoring}) {
			context.commit({
				type: "editExtraActivityMonitoring",
				extraActivityMonitoring
			});
		},
		editProductionStandardTime (context, productionStandardTime: {old: IProductionStandardTime; new: IProductionStandardTime}) {
			context.commit({
				type: "editProductionStandardTime",
				productionStandardTime
			});
		},
		deleteProduct (context, product: IProduct) {
			context.commit({
				type: "deleteProduct",
				product
			});
		},
		deleteIngredient (context, ingredient: IIngredient) {
			context.commit({
				type: "deleteIngredient",
				ingredient
			});
		},
		deleteAllergen (context, allergen: IAllergen) {
			context.commit({
				type: "deleteAllergen",
				allergen
			});
		},
		deleteTablet (context, tablet: ITablet) {
			context.commit({
				type: "deleteTablet",
				tablet
			});
		},
		deleteUser (context, user: IUser) {
			context.commit({
				type: "deleteUser",
				user
			});
		},
		deleteOperator (context, operator: IStationOperator) {
			context.commit({
				type: "deleteOperator",
				operator
			});
		},
		deleteStation (context, station: IStation) {
			context.commit({
				type: "deleteStation",
				station
			});
		},
		deleteNotificationMonitoring (context, notificationMonitoring: INotificationMonitoring) {
			context.commit({
				type: "deleteNotificationMonitoring",
				notificationMonitoring
			});
		},
		deleteExtraActivityMonitoring (context, extraActivityMonitoring: IExtraActivityMonitoring) {
			context.commit({
				type: "deleteExtraActivityMonitoring",
				extraActivityMonitoring
			});
		},
		deleteProductionStandardTime (context, productionStandardTime: IProductionStandardTime) {
			context.commit({
				type: "deleteProductionStandardTime",
				productionStandardTime
			});
		}
	},
	getters: {
		serverURL: (): string => {
			if (process.env.NODE_ENV === "development") {
				return "http://127.0.0.1:5000";
			}

			if (window.location.hostname === "backoffice.omv.ifoods.ro") {
				return "https://server.omv.ifoods.ro";
			}

			if (window.location.hostname === "backoffice-omv.wyliodrin.com") {
				return "https://backoffice-omv.wyliodrin.com/api";
			}

			if (window.location.protocol.includes("https")) {
				return "https://backoffice-omv.wyliodrin.com/api";
			}

			return "http://10.208.93.10";
		},
		appTitle: (state): string => {
			return state.appTitle;
		},
		products (state): IProduct[] {
			return state.products;
		},
		ingredients (state): IIngredient[] {
			return state.ingredients;
		},
		productCategories (state): IProductCategory[] {
			return state.productCategories;
		},
		allergens (state): IAllergen[] {
			return state.allergens;
		},
		ingredientCategories (state): IIngredientCategory[] {
			return state.ingredientCategories;
		},
		tablets (state): ITablet[] {
			return state.tablets;
		},
		users (state): IUser[] {
			return state.users;
		},
		operators (state): IStationOperator[] {
			return state.operators;
		},
		stations (state): IStation[] {
			return state.stations;
		},
		notificationMonitorings (state): INotificationMonitoring[] {
			return state.notificationMonitorings;
		},
		extraActivityMonitorings (state): IExtraActivityMonitoring[] {
			return state.extraActivityMonitorings;
		},
		productionStandardTimes (state): IProductionStandardTime[] {
			return state.productionStandardTimes;
		}
	}
});

// Export the direct-store instead of the classic Vuex store.
export default store;

// The following exports will be used to enable types in the
// implementation of actions and getters.
export {
	rootActionContext,
	moduleActionContext,
	rootGetterContext,
	moduleGetterContext
};

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store
declare module "vuex" {
  interface Store<S> {
    direct: AppStore;
  }
}
